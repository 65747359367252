import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Hem',
    component: () =>
      import(/* webpackChunkName: "Routes" */ '../views/Hem.vue')
  },
  {
    path: '/maskin/:id?',
    name: 'Maskin',
    component: () =>
      import(/* webpackChunkName: "Routes" */ '../views/Maskin.vue')
  },
  {
    path: '/impregnering',
    name: 'Impregnering',
    component: () =>
      import(/* webpackChunkName: "Routes" */ '../views/Impregnering.vue')
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: () =>
      import(/* webpackChunkName: "Routes" */ '../views/Kontakt.vue')
  },
  { 
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    component: () =>
      import(/* webpackChunkName: "Routes" */ '../views/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
