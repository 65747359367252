<template>
  <div>
    <a href="/">
      <img :src="require('@/assets/images/logo-mini.svg')" alt="logo">
    </a>
    <div id="nav">
      <router-link class="router-link" to="/">
        <font-awesome-icon :icon="['fas', 'home']" />
        <span> Hem</span>
      </router-link>
      <router-link class="router-link" to="/maskin">
        <font-awesome-icon :icon="['fas', 'tools']" />
        <span> Maskin</span>
      </router-link>
      <router-link class="router-link" to="/impregnering">
        <font-awesome-icon :icon="['fas', 'faucet']" />
        <span> Impregnering</span>
      </router-link>
      <router-link class="router-link" to="/kontakt">
        <font-awesome-icon :icon="['fas', 'paper-plane']" />
        <span> Kontakt</span>
      </router-link> 
    </div>
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
  <footer> 
    <FooterComponent></FooterComponent>
  </footer>
</template>
<script>
  import FooterComponent from '@/components/FooterComponent.vue'

  export default {
    name: 'Default',
    components: {
      FooterComponent
    },
  }
</script>
